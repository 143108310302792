import FormButton from '@/components/Form/FormButton'
import FormGroup from '@/components/Form/FormGroup'
import FormInput from '@/components/Form/FormInput'
import FormTextRequire from '@/components/Form/FormTextRequire'
import FormView from '@/components/Form/FormView'
import { IconHomeDark } from '@/components/Icon/HeaderIcon'
import { LoginSlider } from '@/components/LoginSlider'
import { BannerType, SaleChannel } from '@/constants'
import { useAuth, useNotification } from '@/containers'
import { useCookie } from '@/hooks/useCookie'
import { useRouterBack } from '@/hooks/useRouterBack'
import { IconBackDark, IconPhone } from '@/styles/Icon'
import { type BannerList } from '@/types'
import { api } from '@/utils'
import { setContext } from '@/utils/api-interceptors'
import { getBanner } from '@/utils/endpoint'
import { getCookie, getCookies } from 'cookies-next'
import { useFormik } from 'formik'
import type { GetServerSideProps } from 'next'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import * as Yup from 'yup'
type LoginProps = {
  banner: BannerList[]
}

const Login = ({ banner }: LoginProps) => {
  const [, setPhoneNumber] = useCookie('phoneNumber', {})
  const [, setRequestId] = useCookie('requestId', {})
  const [isProcessing, setIsProcessing] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const { token } = useAuth()
  const { systemConfig } = useNotification()
  const [checkRedirectUrl, setCheckRedirectUrl] = useState(false)
  const router = useRouter()

  const onSubmitFn = () => {
    setIsSubmitted(true)
  }
  const handleGoBack = useRouterBack()

  useEffect(() => {
    if (router.query.redirect) {
      return setCheckRedirectUrl(true)
    }
    return
  }, [router])
  if (token) {
    router.push('/')
  }

  const formik = useFormik({
    initialValues: {
      phone: '',
    },
    validationSchema: Yup.object().shape({
      phone: Yup.string()
        .min(10, '*Số điện thoại của bạn không đúng định dạng')
        .max(11, '*Số điện thoại của bạn không đúng định dạng')
        .required('*Vui lòng nhập số điện thoại'),
    }),

    onSubmit: async values => {
      setErrorMessage('')
      setIsProcessing(true)
      const res = await api(`api/auth/login/request-otp`, {
        method: 'post',
        baseURL: process.env.NEXT_PUBLIC_AUTH_API_URL,
        data: {
          ...values,
          channel: localStorage?.getItem('app') || getCookie('app') || null,
        },
      })
      if (res instanceof Error) {
        setErrorMessage(res.message)
        setIsProcessing(false)
      }
      setPhoneNumber(values.phone)
      setIsProcessing(false)
      setRequestId(res.data.requestId)
      if (checkRedirectUrl) {
        router.push(`/otp?redirect=${router.query.redirect}`)
      } else {
        router.push('/otp')
      }
    },
  })

  return (
    <>
      <main>
        {getCookie('app') === SaleChannel.B2E ? (
          ''
        ) : (
          <header
            className={`fixed left-[50%] translate-x-[-50%] top-0 z-[999] flex h-[60px] w-full max-w-[440px] items-center justify-between shadow-100`}
          >
            <div className='flex h-[60px] items-center justify-between px-1 w-full'>
              <div className='flex-[40px] max-w-[40px]'>
                <button
                  className='h-10 w-10 outline-none flex justify-center items-center'
                  onClick={() => handleGoBack(true)}
                >
                  <IconBackDark />
                </button>
              </div>
              <div className='flex-1 w-full px-1'></div>
              <div className='flex-[40px] max-w-[40px]'>
                <Link
                  className='h-10 w-10 outline-none flex justify-center items-center'
                  href={'/'}
                >
                  <IconHomeDark />
                </Link>
              </div>
            </div>
          </header>
        )}

        <div className=' m-auto flex flex-wrap max-w-[440px] items-end relative min-h-[100vh] h-full bg-gray-200'>
          <div className='absolute left-0 top-0 w-full'>
            <LoginSlider
              sliders={
                banner?.find((e: BannerList) => e.type === BannerType.LOGIN)
                  ?.items
              }
            ></LoginSlider>
          </div>
          <div className='container relative z-10'>
            <div className='flex flex-wrap pb-5 px-2 text-center'>
              <div className='text-lg font-semibold mb-5 text-center m-auto text-dark-400'>
                Đăng nhập OTP
              </div>
              <FormView
                onSubmit={formik.handleSubmit}
                buttonClassName='mt-[12px]'
              >
                <FormGroup className='flex w-full flex-col relative'>
                  {errorMessage && isSubmitted && (
                    <FormTextRequire className='text-primary-second text-left pl-3 mb-2 text-textSmall'>
                      {errorMessage}
                    </FormTextRequire>
                  )}
                  {isSubmitted && formik.errors.phone && (
                    <FormTextRequire className='text-primary-second text-left pl-3 mb-2 text-textSmall'>
                      {formik.errors.phone}
                    </FormTextRequire>
                  )}
                  <FormInput
                    autoComplete='off'
                    name='phone'
                    placeholder='Nhập số điện thoại đã đăng ký'
                    value={formik.values.phone.trim()}
                    isError={isSubmitted && formik.errors.phone}
                    onChange={e => {
                      formik.handleChange({
                        target: {
                          value: e.target.value.trim().replace(/\D/, ''),
                          name: 'phone',
                        },
                      })
                      setErrorMessage('')
                    }}
                    alert
                    icon={
                      <IconPhone className='absolute left-3 bottom-[12px]' />
                    }
                    className={`relative pl-[48px] !h-[50px] w-full rounded-lg border-0 text-left text-base outline-0  ${
                      isSubmitted && formik.errors.phone ? '!border-red' : ''
                    }`}
                  />
                </FormGroup>
                <FormButton
                  className={`bg-primary-base h-12 w-full rounded-lg overflow-hidden outline-0 font-medium text-white text-base`}
                  onClick={onSubmitFn}
                  disabled={isProcessing}
                >
                  Nhận mã OTP
                </FormButton>
              </FormView>
              <div className='mt-5 m-auto text-center'>
                <Link
                  className='block text-base font-normal text-primary-base mb-4'
                  href='/register'
                >
                  Đăng ký thành viên
                </Link>
                <span className='text-dark-800 block text-textSmall'>
                  Hotline: {systemConfig?.companyPhone || ''}
                </span>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default Login

export const getServerSideProps: GetServerSideProps = async ({
  req,
  res,
  locale,
  query,
}) => {
  const { language, messages } = await setContext(getCookies({ req, res }), {
    language: locale,
    channel: (query?.app || SaleChannel.B2C) as string,
  })

  const [banner] = await Promise.all([getBanner()])
  return {
    props: {
      messages,
      language,
      banner,
    },
  }
}
