interface FormTextRequire {
  children?: string
  className?: string
}

function FormTextRequire({ children, className = '' }: FormTextRequire) {
  return <div className={`${className}`}>{children}</div>
}
FormTextRequire.displayName = 'FormTextRequire'
export { FormTextRequire }
export default FormTextRequire
