import type { BannerItem } from '@/types'
import Link from 'next/link'
import { useRef, type FC } from 'react'
import type { Settings } from 'react-slick'
import Slider from 'react-slick'
import ImageOptimize from '../ImageOptimize'
interface Props {
  sliders?: Array<BannerItem>
}
export const LoginSlider: FC<Props> = ({ sliders }: Props) => {
  const sliderSettings: Settings = {
    autoplay: true,
    dots: true,
    autoplaySpeed: 3000,
    speed: 500,
    arrows: false,
    cssEase: 'linear',
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
  }
  const slider = useRef<Slider>(null)

  return (
    <div className={``}>
      <div>
        <Slider
          ref={slider}
          {...sliderSettings}
          className='max-w-screen login flex'
        >
          {sliders &&
            sliders.map((slider: BannerItem, index: number) => (
              <Link
                href={slider.link || '/'}
                key={index}
                className='outline-non block'
              >
                <ImageOptimize
                  className='w-full object-cover'
                  src={slider.image}
                  alt={slider.title}
                  width={428}
                  height={654}
                />
              </Link>
            ))}
        </Slider>
      </div>
    </div>
  )
}
